function hideSnackbarAfterMount(snackbar) {
  const timer = parseInt(snackbar.dataset?.hideAfter || 4000, 10);
  const skipHide = snackbar.dataset?.skipHide;
  if (skipHide) return;
  setTimeout(() => snackbar.classList.add('tw-hidden'), timer);
}
window.hideSnackbarAfterMount = hideSnackbarAfterMount;
function toggleSnackbar(id, description) {
  const snackbar = document.getElementById(id);
  if (description) {
    snackbar.querySelector('.description').textContent = description;
  }
  snackbar.classList.remove('tw-hidden');
  hideSnackbarAfterMount(snackbar);
}
window.toggleSnackbar = toggleSnackbar;
document.addEventListener('DOMContentLoaded', () => {
  const successSnackbar = document.getElementById('success-snackbar');
  const warningSnackbar = document.getElementById('warning-snackbar');
  if (successSnackbar) hideSnackbarAfterMount(successSnackbar);
  if (warningSnackbar) hideSnackbarAfterMount(warningSnackbar);
});
